.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 95vh;
    background-color: rgba(0, 0, 0, 0.01); /* 半透明黑色背景 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* 确保遮罩层在最上层 */
    cursor: pointer; /* 鼠标悬停显示指针 */
  }
  
  .play-button {
    background: none;
    border: none;
    font-size: 2rem;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    outline: none;
    transition: transform 0.2s ease-in-out;
  }
  
  .play-button:hover {
    transform: scale(1.1);
  }