p,div,h1,h2,h3,h4,span,br {
    color: rgb(128,128,128);
  }

p {
    font-size: 12px; /* Adjust the font size as needed */
}

/* 容器样式 */
.container {
  max-width: 400px; /* 设置为手机尺寸宽度 */
  margin: 0 auto;   /* 居中布置 */
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* 可选：添加阴影效果 */
}

/* 媒体查询：在 PC 端或平板设备上应用样式 */
@media (min-width: 768px) {
  .container {
    max-width: 500px; /* 保持手机尺寸宽度 */
  }
}